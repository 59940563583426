"use client";

import * as React from "react";
import { Moon, Sun, Smartphone } from "lucide-react";
import { useTheme } from "next-themes";
import { twJoin } from "tailwind-merge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Skeleton } from "@/components/ui/skeleton";

/**
 * @description Get the classes for the theme icon
 * @param theme - The theme this icon represents
 * @param currentTheme - The current theme
 * @returns The theme icon class name
 */
const getThemeIconClassName = (theme: string, currentTheme: string = "light") =>
  twJoin(
    "absolute h-[1.2rem] w-[1.2rem] transition-all",
    theme === currentTheme ? "rotate-0 scale-100" : "rotate-90 scale-0",
  );

export function ThemeToggle() {
  const { setTheme, resolvedTheme, theme } = useTheme(),
    [mounted, setMounted] = React.useState(false),
    [currentTheme, setThemeState] = React.useState(theme);

  const changeTheme = (themeValue: string) => {
    setTheme(themeValue);
    setThemeState(themeValue);
  };

  React.useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted)
    return (
      <div className="h-[2.875rem]">
        <Skeleton className="h-10 w-10" />
      </div>
    );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <Sun className={getThemeIconClassName("light", resolvedTheme)} />
          <Moon className={getThemeIconClassName("dark", resolvedTheme)} />
          <Smartphone
            className={getThemeIconClassName("black", resolvedTheme)}
          />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuRadioGroup value={currentTheme}>
          <DropdownMenuRadioItem
            value="light"
            onClick={() => changeTheme("light")}
          >
            Light
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem
            value="dark"
            onClick={() => changeTheme("dark")}
          >
            Dark
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem
            value="black"
            onClick={() => changeTheme("black")}
          >
            Black
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem
            value="system"
            onClick={() => changeTheme("system")}
          >
            System
          </DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
